.home {
  .single {
    margin-top: 16px;
    &-title {
      font-size: 2rem !important;
    }
  }
}
.home .home-profile {
  text-align: left !important;
  .home-title {
    font-size: 2.2rem !important;
    @media only screen and (min-width: 681px) {
      font-size: 3rem !important;
      margin-top: 30px !important;
    }
  }
}
.home-profile > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.home[posts] .summary {
  padding : 0 0 32px !important;
  border-radius: 20px 20px 18px 18px;
  border: 2px solid var(--global-link-color) !important;
}

.article-metadata {
  padding : 0 32px 0 !important;
}