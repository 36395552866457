// --- IBM Plex Sans -- //
@font-face {
  font-family: 'plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/plex-sans/IBMPlexSans-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'plex-sans-bold';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/plex-sans/IBMPlexSans-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'plex-sans-italic';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/plex-sans/IBMPlexSans-SemiBoldItalic.woff2) format('woff2');
}
@font-face {
  font-family: 'plex-serif';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/plex-serif/IBMPlexSerif-SemiBold.woff2) format('woff2');
}

$other-font-families: $global-font-family;
$global-font-family: plex-sans, $global-font-family;

h1, h2 {
  font-family: plex-serif, $other-font-families;
}

h3, h4 {
  font-family: plex-sans-italic, $other-font-families;
}

.footer, .header-title, .home-title, .related-title, .single-title, .toc-title {
  font-family: plex-serif, $other-font-families !important;
}

.menu, .toc-content {
  font-family: plex-sans, $other-font-families !important;
}