@import "colors";
@import "fonts";
@import "header";
@import "home";
@import "single";
@import "summaries";

article > h1 {
  font-size: 1rem !important;
  @media only screen and (min-width: 681px) {
    font-size: 2.5rem !important;
  }
}

.footer {
  margin-top: 1rem;
}

a:hover {
  text-decoration: underline !important;
}