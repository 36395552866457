.single {
  #content > p, #content > ol, #content > ul, .admonition {
    margin: 1rem 0 !important;
    @media only screen and (min-width: 681px) {
      font-size: 1.2rem !important;
      line-height: 165% !important;
    }
  }
  img {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    margin-top: 0;
  }
}
.content {
  h2 {
    margin: 2rem 0 2rem !important;
    >.header-mark::before {
      content: "#" !important;
    }
    @media only screen and (min-width: 681px) {
      font-size: 2.5rem !important;
    }
  }
  h3>.header-mark::before, h4>.header-mark::before {
    content: ">" !important;
  }
  img {
    border-radius: 18px;
  }
}
.page > .single-title {
  font-size: 2rem !important;
  @media only screen and (min-width: 681px) {
    font-size: 2.5rem !important;
  }
}
.related-item-container {
  border-radius: 18px;
}