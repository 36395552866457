//--- My variables ---//
// Apple Logo
$apple-logo-green: #84B655;
$apple-logo-yellow: #EAC04E;
$apple-logo-orange: #D59748;
$apple-logo-red: #B75856;
$apple-logo-purple: #8A5281;
$apple-logo-blue: #469CD7;

// My colors
//Primary color: Canard Colvert
$primary-color: #026E6A;

//--- DoIt variables --- //
// Light mode
$link-color-light: $primary-color;
$link-color-accent-light: $primary-color;
// Dark mode
$link-color-dark: $primary-color;
$link-color-accent-dark: $primary-color;

:root {
  --global-font-color: #222;
  --global-font-secondary-color: #4d4d4d;

  --header-background-color: #FFF;

  --global-link-color: #222;
  --global-link-hover-color: #222;

  //// --- single link --- > link to other article for exemple
  --single-link-color: #026E6A;
  --single-link-hover-color: #222;
  --related-hover-color: #026E6A;

  .summary {
    &:hover {
      a {
        color: #026E6A;
      }
    }
  }
}

html.dark {
  --global-background-color: #111111;
  --header-background-color: #111111;

  //// --- single link --- > link to other article for exemple
  --single-link-color: #026E6A;
  --single-link-hover-color: #026E6A;
  --related-hover-color: #026E6A;

// TODO : update Disqus config ?
//  #disqus_thread {
//    background-color: #121212
//  }
//
//  .summary {
//    &:hover {
//      a {
//        color: #7C3AED;
//      }
//    }
//  }
}

@media (prefers-color-scheme: light) {
  iframe[src*="disqus.com"] {
    color-scheme: dark;
  }
}
@media (prefers-color-scheme: dark) {
  iframe[src*="disqus.com"] {
    color-scheme: light;
  }
}

header {
  border-bottom: 2px solid var(--global-link-color) !important;
}
footer {
  border-top: 2px solid var(--global-link-color) !important;
}

.single-title {
  color: $primary-color !important;
}